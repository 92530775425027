@import './../../assets/scss/_colors.scss';

.error404{
  background: $color-dark url("./../../assets/images/background_desk.webp") no-repeat fixed center bottom / cover; padding: 50px 0; color: #fff; font-family:'Lucida Console';
  h1{
    font-size: 10em; color: #c665fd; margin: 20px auto;
  }
  .error404--tag{
    color: #fff; font-size: 0.7em; position: relative; top:-20px;
  }
  .error404--code{
    color: #fff; text-align: left; font-weight: bold; width: 290px; max-width: 100%; margin: auto; font-family:'Lucida Console';
  }
  .error404--color1{
    color: #96cbfe;
  }
  .error404--color2{
    color: #c665fd;
  }
  .error404--color3{
    color: #a8f13e;
  }
}
/* style mobile */
@media(max-width: 640px) {
	.error404 h1{
    font-size: 6em;
	}
}
@media(max-width: 350px) {
	.error404 h1{
    font-size: 4em;
	}
}
/* style print */
@media print {
  .error404{
    padding:0; color: inherit;
    .error404--tag{
      color: inherit;
    }
  }
}