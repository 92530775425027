@import './../../assets/scss/_colors.scss';

.scripts{
	max-width: 900px; margin: auto; padding: 50px 0 100px;
	.scripts--half{
		flex: 0 0 50%; background-color: $color-light;	overflow: hidden;
	}
	.scripts--description{
		padding: 20px;     display: flex;    flex-flow: column;    align-items: center;    justify-content: center;
	}
	.script{
		h2{
			font-size: 2em;  font-family: 'Montserrat', sans-serif;
		}
		hr{
			width: 50px; height: 2px; border-color: $color-primary; margin: 12px auto;
		}
		img{
			width:100%; height: 100%; transition: all 0.3s; 
		}
		a{
			color: #000; transition: all 0.2s; position: relative; display: block;
			&:hover{
				color: $color-primary;
				img{
					transform:scale(1.1); opacity: 0.5;
				}
			}
		}
		p{
			max-width: 250px; margin-bottom:0;
		}
	}
}
/* style mobile */
@media(max-width: 640px) {
	.scripts{
		.script{
			display: block;
		}
		.scripts--image{
			display: none !important;
		}
		.scripts--description{
			padding: 30px 10px; border-bottom: solid 1px grey;
		}
	}
}
/* style print */
@media print{
	.scripts .scripts--description{
		border: none; padding:0;
	}
}