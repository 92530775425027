@import './../../assets/scss/_colors.scss';

.footer {
	background-color: #000;
	padding: 30px 10px 10px;
	color: $color-light;
	font-size: 0.85rem;

	a {
		color: $color-light;
		transition: all 0.2s;
	}

	a:hover {
		text-decoration: underline;
	}

	a:hover,
	a:focus {
		color: #fff;
	}
}

.footer--top {
	flex: 0 0 calc(33% - 30px);
	margin: 15px;
	padding-bottom: 15px;
}

.footer--social {
	font-size: 2em;
  max-width: 260px;
  margin: auto;
}

.footer--social a {
	margin: 15px;
}

.footer--bottom {
	font-family: 'Lucida Console';
	padding: 30px 10px 10px;

	p {
		font-size: 0.8em;
	}
}

/* style mobile */
@media(max-width: 768px) {
	.footer .d-flex {
		display: block;
		text-align: center;
	}
}

/* style print */
@media print {

	.footer,
	.footer a {
		color: #000;
	}

	.footer--social {
		display: none !important;
	}
}