@import './../../assets/scss/_colors.scss';

.script{
	a{
		color: $color-primary;
		&:hover{
			color: #652596;
		}
	}
	.features{
		padding-bottom: 30px; flex: 0 0 33%;
		.img{
			margin: auto;
		}
		h4{
			padding: 15px 20px 5px; text-transform: uppercase; font-size: 1.5em;
		}
		p{
			padding: 5px; max-width: 300px; margin: auto;
		}
	}
	.functions{
		flex: 0 0 calc(33% - 40px); background-color: $color-light; margin: 20px; padding: 30px 10px;
		h4{
			padding: 15px 20px 5px; font-size: 1.5em;
		}
		.img{
			margin: auto;
		}
	}
	.script--requirements{
		flex: 0 0 33%; padding: 20px 10px;
		.img{
			margin: 0 auto 15px;
		}
	}
}
.script--up{
	background-color: $color-dark; background-image: url('./../../assets/images/background_dark.webp'); background-position: center top; background-size: 100% 100%; color: #fff;
}
.script--up--middle{
	padding: 120px 10px 0;
	img{
		margin: 90px auto 0; max-width: 100%; display: block; height: auto;
	}
}
.script--up--bottom h2{
	margin-bottom: 20px;
}
.script--admin--panel .img, .script--language .img{
	margin: 20px auto 30px;
}
.script--gallery--photos{
	display: grid;		grid-template-columns: repeat(auto-fill, 16.7%); padding: 5px; background-color: white;
}
.gallery--photo{
	cursor: pointer; border: none; transition: all .2s; margin: 5px;  background-color: white; 	box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
	&:first-child{
		grid-column: 1 / 5;
		grid-row:  1 / 3; 
	}
	&:nth-child(2), &:nth-child(3){
		grid-column: 5 / 7;
	}
	&:nth-child(4){
		grid-column: 1 / 3;
		grid-row:  3 / 4; 
	}
	&:nth-child(5){
		grid-column: 3 / 5;
		grid-row:  3 / 4; 
	}
	&:nth-child(6){
		grid-column: 5 / 7;
		grid-row:  3 / 4; 
	}
	&:hover{
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
	}
}
.script--info{
	padding: 20px;
	p{
		margin-bottom:0;
	}
}
.script--buy{
	display: inline-block; padding: 10px 20px; border: solid 3px $color-primary;  transition: all 0.2s; font-size: 1.3em; margin-top: 20px; color: $color-primary !important; font-weight: bold;
	&:hover{
		background-color: $color-primary; color:#fff !important;
	}
}
/* style mobile */
@media(max-width: 950px) {
	.script .functions{
		flex-basis: calc(50% - 40px);
	}
}
@media(max-width: 768px) {
	.script .d-flex{
		justify-content: center;
	}
	.script .features, .script .script--requirements{
		flex-basis: 50%;
	}
	.script .functions{
		flex-basis: calc(50% - 40px);
	}
	.script--gallery--photos{
		display: block; 
	}
}
@media(max-width: 650px) {
	.script .features, .script .script--requirements, .script .functions{
		flex-basis: 100%;
	}
}
/* style print */
@media print{
	.script--up--middle{
		padding: 30px 0 20px;
	}
	.script--up--middle img{
		margin-top: 30px;
	}
	.script--up h1, .script--up h3{
		color: inherit;
	}
	.script .features .img, .script .functions .img, .script .script--requirements .img, .script--admin--panel .img, .script--language .img, .script--gallery{
		display: none;
	}
	.script .features{
		padding-bottom: 0;
	}
	.script .functions{
		padding:0; margin: 10px;
	}
}