@import './../../assets/scss/_colors.scss';

.preloader {
    background-color: #fff; padding: 200px 0; justify-content: center; align-items:center;
    .cssload-container {
        display: block;  width: 97px;
    }
    .cssload-loading i {
        width: 19px;   height: 19px; display: inline-block;  border-radius: 50%; background: $color-primary;
        &:first-child {
        opacity: 0; animation: cssload-loading-ani2 0.58s linear infinite; transform: translate(-19px);
        }
        &:nth-child(2), &:nth-child(3) {
        animation: cssload-loading-ani3 0.58s linear infinite;
        }
        &:last-child {
        animation: cssload-loading-ani1 0.58s linear infinite;
        }
    }
}
@keyframes cssload-loading-ani1{
100% {
    transform: translate(39px);  opacity: 0;
    }
}
@keyframes cssload-loading-ani2 {
100% {
    transform: translate(19px);  opacity: 1;
    }
}
@keyframes cssload-loading-ani3 {
100% {
    transform: translate(19px);
    }
}