@import './../../assets/scss/_colors.scss';

.home--header{
	background: #0e0e0e url("./../../assets/images/header.webp") no-repeat fixed center bottom / cover; 
	padding-top: 170px; padding-bottom: 130px; text-shadow: 1px 1px 2px #000; color: #fff; 
}
.home--header--inside{
	margin-left: 10%; border-bottom: solid 1px grey; padding: 0 20px 20px 0;
}
.home--header--typed{
	margin-top: 30px; margin-bottom: 40px; font-size: 3.4em; font-weight: bold; font-family: 'Montserrat', sans-serif; 
}
.home--header--typed-cursor{
	color: $color-primary; animation-name: showHide; animation-duration: 0.8s; animation-iteration-count: infinite;
}
.home--introduction--text{
	column-count: 2; column-gap: 2em; margin-top: 50px; 
}
.home--about_me{
	background: $color-dark url("./../../assets/images/background_desk.webp") no-repeat fixed center bottom / cover;  color: $color-light; text-align: center;
	p{
		margin-top: 10px; margin-bottom: 0;
	}
	a{
		color: #fff; margin: 0 12px 12px 0; transition: all 0.1s; font-size: 1.6em;
	}
	a:hover{
		color: $color-primary;
	}
}
.home--skill{
	background-color: #000; margin: 15px; padding: 30px 20px; color: #fff; border-bottom: solid 4px $color-primary; flex: 0 0 calc(25% - 30px); text-align: center; transition: all 0.4s; position: relative; top:0;
	&:hover{
		top:-10px;
	}
	.home--skill--image{
		background-image: url('./../../assets/images/skills.webp'); width: 60px; height: 60px; margin: 0 auto 30px;
	}
	&:nth-child(2) .home--skill--image{
		background-position: 0 -60px;
	}
	&:nth-child(3) .home--skill--image{
		background-position: 0 -120px;
	}
	&:nth-child(4) .home--skill--image{
		background-position: 0 -180px;
	}
	&:nth-child(5) .home--skill--image{
		background-position: 0 -240px;
	}
	&:nth-child(6) .home--skill--image{
		background-position: 0 -300px;
	}
	&:nth-child(7) .home--skill--image{
		background-position: 0 -360px;
	}
	&:nth-child(8) .home--skill--image{
		background-position: 0 -420px;
	}
	p{
		color: $color-light; font-size: 0.9em;
	}
	a{
		color: #fff;
	}
}
.home--technologie{
	margin: 15px; padding: 30px 20px; border: solid 1px $color-light; position: relative; transition: all 0.4s; top:0; flex: 0 0 calc(25% - 30px); text-align: center;
	box-shadow: 0 0 .5rem rgba(129, 95, 95, 0.08);
	&:hover{
		top:-10px;
	}
	p{
		font-size: 0.9em;
		a{
			color: $color-primary;
		}
	}
	.home--technologie--image{
		background-image: url('./../../assets/images/technologies.webp'); width: 60px; height: 60px; margin: 0 auto 30px;
	}
	&:nth-child(2) .home--technologie--image{
		background-position: 0 -60px;
	}
	&:nth-child(3) .home--technologie--image{
		background-position: 0 -120px;
	}
	&:nth-child(4) .home--technologie--image{
		background-position: 0 -180px;
	}
	&:nth-child(5) .home--technologie--image{
		background-position: 0 -240px;
	}
	&:nth-child(6) .home--technologie--image{
		background-position: 0 -300px;
	}
	&:nth-child(7) .home--technologie--image{
		background-position: 0 -360px;
	}
	&:nth-child(8) .home--technologie--image{
		background-position: 0 -420px;
	}
	&:nth-child(9) .home--technologie--image{
		background-position: 0 -480px;
	}
	&:nth-child(10) .home--technologie--image{
		background-position: 0 -540px;
	}
	&:nth-child(11) .home--technologie--image{
		background-position: 0 -600px;
	}
	&:nth-child(12) .home--technologie--image{
		background-position: 0 -660px;
	}
	&:nth-child(13) .home--technologie--image{
		background-position: 0 -720px;
	}
	&:nth-child(14) .home--technologie--image{
		background-position: 0 -780px;
	}
	&:nth-child(15) .home--technologie--image{
		background-position: 0 -840px;
	}
	&:nth-child(16) .home--technologie--image{
		background-position: 0 -900px;
	}
}
/* style mobile */
@media(max-width: 992px) {
	.home--skill, .home--technologie{
		flex: 0 0 calc(33% - 30px);
	}
}
@media(max-width: 768px) {
	.home--header--typed{
		font-size: 2.7em;
	}
	.home--introduction--text{
		column-count: 1;
	}
	.home--skill, .home--technologie{
		flex: 0 0 calc(50% - 30px);
	}
}
@media(max-width: 570px) {
	.home--skills, .home--technologies{
		display: block;
	}
	.home--header--typed{
		font-size: 2.5em;
	}
}
/* style print */
@media print {
	.home-header{
		padding: 40px 0 0; text-shadow: none; color: inherit; text-align: center;
	}
	.home--header--inside{
		padding:0; margin:0; border: none;
	}
	.home--about_me{
		padding:0; color: inherit;
	}
	.home--header--typed, .home--skill--image, .home--technologie--image{
		display: none;
	}
	.home--skill, .home--technologie{
		color: inherit; border: none; margin:0; padding:0;
	}
}